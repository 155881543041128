import NextLink from "next/link";
import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { ROUTES } from "~/core/constants";

export default function NotFound() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="2xl">
        404
      </Heading>
      <Text fontSize="large" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color="gray.500" mb={6}>
        The page you&apos;re looking for does not seem to exist
      </Text>
      <NextLink href={ROUTES.homepage} passHref>
        <Button colorScheme="blue">Go to Home</Button>
      </NextLink>
    </Box>
  );
}
